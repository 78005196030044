/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as o } from "../chunks/tslib.es6.js";
import s from "./Accessor.js";
import "./has.js";
import { tryClone as t } from "./lang.js";
import "./Logger.js";
import { assertIsSome as r } from "./maybe.js";
import { OriginId as e } from "./accessorSupport/PropertyOrigin.js";
import { getProperties as n } from "./accessorSupport/utils.js";
import { subclass as c } from "./accessorSupport/decorators/subclass.js";
const i = s => {
  let i = class extends s {
    clone() {
      const o = n(this);
      r(o, "unable to clone instance of non-accessor class");
      const s = o.metadata,
        c = o.store,
        i = {},
        l = new Map();
      for (const r in s) {
        const o = s[r],
          n = c?.originOf(r),
          a = o.clonable;
        if (o.readOnly || !1 === a || n !== e.USER && n !== e.DEFAULTS && n !== e.WEB_MAP && n !== e.WEB_SCENE) continue;
        const p = this[r];
        let f = null;
        f = "function" == typeof a ? a(p) : "reference" === a ? p : t(p), null != p && null == f || (n === e.DEFAULTS ? l.set(r, f) : i[r] = f);
      }
      const a = new (0, Object.getPrototypeOf(this).constructor)(i);
      if (l.size) {
        const o = n(a)?.store;
        if (o) for (const [s, t] of l) o.set(s, t, e.DEFAULTS);
      }
      return a;
    }
  };
  return i = o([c("esri.core.Clonable")], i), i;
};
let l = class extends i(s) {};
l = o([c("esri.core.Clonable")], l);
export { l as Clonable, i as ClonableMixin };